import React, { useContext, useEffect, useRef, useState } from 'react';
import { InputGroup, Button, Form, Modal, Collapse } from "react-bootstrap";
import "../../css/landing/landing.css";
import "../../css/landing/bootstrap.min.css";
import "../../css/landing/glightbox.min.css";
import "../../css/landing/main.css";
import "../../css/landing/tiny-slider.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { useTranslation } from 'react-i18next';
import { Context } from '../../MasterLayout';
import { GeneralContext } from '../../GeneralLayout';
import { useParams, Link, useNavigate } from 'react-router-dom';
import Axios from '../plugins/axios';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeCard from "../components/StripeCard/Stripecard";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const InfluencersDetails = () => {
   const params = useParams();
   const { t } = useTranslation();
   const navigate = useNavigate();
   const isUserLoggedIn = localStorage.getItem('user') && localStorage.getItem('token') ? true : false;
   const currentContext = localStorage.getItem('user') && localStorage.getItem('token') ? Context : GeneralContext;
   const { dispatch } = useContext(currentContext);
   const buyArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
   const stripeRef = useRef(null);
   const addressRef = useRef(null);
   const [influencer, setInfluencer] = useState(null);
   const [userFetch, setUserFetch] = useState(true);
   const [user, setUser] = useState(null);
   const [show, setShow] = useState(false);
   const [couponModel, setCouponModel] = useState(false);
   const [open, setOpen] = useState(false);
   const [name, setName] = useState('');
   const [nameMessage, setNameMessage] = useState('');
   const [address, setAddress] = useState(null);
   const [addressMessage, setAddressMessage] = useState('');
   const [stripeMessage, setStripeMessage] = useState('');
   const [emailMessage, setEmailMessage] = useState('');
   const [email, setEmail] = useState('');
   const [phoneMessage, setPhoneMessage] = useState('');
   const [phone, setPhone] = useState('');
   const [reinit, setReinit] = useState(0);
   const [currentObject, setCurrentObject] = useState(null);
   const [qty, setQty] = useState(1);
   const [errorMessage, setErrorMessage] = useState('');
   const [paid, setPaid] = useState(false);
   const [redirectPage, setRedirectPage] = useState('/content-creation');
   const [coupon, setCoupon] = useState({ coupon: '', errorMsg: '', validCoupon: false });
   const [paymentMethodModel, setPaymentMethodModel] = useState(false);

   useEffect(() => {
      if (paid === true) navigate(redirectPage);
      if (userFetch === true && isUserLoggedIn === true) getUserDetail();
      getInfluencerVideo();
   }, [reinit]);

   const resetStates = () => {
      setShow(false); setCouponModel(false); setOpen(false); setName(''); setNameMessage(''); setAddress(null); setAddressMessage(''); setStripeMessage(''); setEmailMessage(''); setEmail(''); setPhoneMessage(''); setPhone(''); setCurrentObject(null); setQty(1); setErrorMessage(''); setPaid(false); setRedirectPage('/content-creation'); setCoupon({ coupon: '', errorMsg: '', validCoupon: false }); setPaymentMethodModel(false); setReinit(0);
   };

   const getInfluencerVideo = async () => {
      try {
         dispatch({ type: 'loader_show', response: true });
         const response = await Axios.get('/api/influencer-videos?_id=' + params.influencerid);
         if (response && response.status === 200 && response.data?.data && response.data.data?.results && response.data.data.results.length > 0) {
            setInfluencer(response.data.data.results[0]);
         };
      } catch (error) {
         console.error('Error-Catch: ' + error.message);
      };
      dispatch({ type: 'loader_hide', response: true });
   };

   const checkemail = (value) => {
      setEmail(value);
      // eslint-disable-next-line no-useless-escape
      const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!email) {
         setEmailMessage(t('registration.step_1.email_address_is_required'));
      } else {
         if (emailRegex.test(email)) {
            setEmailMessage('');
         } else {
            setEmailMessage(t('registration.step_1.long_text_5'));
         };
      };
   };

   const checkPhoneNumber = (value) => {
      setPhone(value);
      const phoneRegex = /^(?:[0-9\-\\(\\)\\/.]\s?){6,15}[0-9]{1}$/;
      if (!phone) {
         setPhoneMessage(t('registration.step_3.long_text_10'));
      } else {
         if (phoneRegex.test(phone)) {
            setPhoneMessage('');
         } else {
            setPhoneMessage(t('registration.step_3.valid_phone'));
         };
      };
   };

   const onSelectAddress = async () => {
      const autocomplete = new window.google.maps.places.Autocomplete(addressRef.current);

      window.google.maps.event.addListener(autocomplete, 'place_changed', async function () {
         const place = await autocomplete.getPlace();
         if (place.address_components) {
            let addressObj = {
               address: place.formatted_address,
               postal: place.address_components[place.address_components.length - 1]?.long_name,
               country: place.address_components[place.address_components.length - 2]?.long_name,
               state: place.address_components[place.address_components.length - 3]?.long_name,
               city: place.address_components[place.address_components.length - 4]?.long_name,
            };
            setAddress(addressObj)
         };
      });
   };

   const payNow = async () => {
      // eslint-disable-next-line no-useless-escape
      const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const phoneRegex = /^(?:[0-9\-\\(\\)\\/.]\s?){6,15}[0-9]{1}$/;

      if (!isUserLoggedIn && (!name || !email || !emailRegex.test(email) || !phone || !phoneRegex.test(phone) || !address)) {
         if (!name) {
            setNameMessage(t('registration.step_1.long_text_4'));
         };
         if (!email) {
            setEmailMessage(t('registration.step_1.long_text_2'));
         }
         if (!emailRegex.test(email)) {
            setEmailMessage(t('registration.step_1.long_text_5'));
         }
         if (!phone) {
            setPhoneMessage(t('registration.step_1.phone_number_is_required'));
         };
         if (!phoneRegex.test(phone)) {
            setPhoneMessage(t('registration.step_3.valid_phone'));
         };
         if (!address) {
            setAddressMessage(t('registration.step_1.address_is_required'));
         };
      } else {
         try {
            dispatch({ type: 'loader_show', response: true });
            let dataObj;

            if (!isUserLoggedIn) {
               let stripeResponse = await stripeRef.current.stripePaymentMethod(name, email);
               if (stripeResponse.statusCode === 200 && stripeResponse.data && stripeResponse.data.id) {

                  dataObj = {
                     name: name,
                     email: email.toLocaleLowerCase(),
                     mobileNumber: phone,
                     paymentMethodId: stripeResponse.data.id,
                     address_details: address,
                     price: currentObject.price,
                     qty: qty,
                     influancer_video: currentObject
                  };
               } else {
                  setStripeMessage(stripeResponse.message);
                  console.error('Stripe-Error: ' + stripeResponse.message);
               };
            } else {
               if (user.stripe_id) {
                  dataObj = {
                     price: currentObject.price,
                     qty: qty,
                     influancer_video: currentObject,
                     user: user
                  };
               } else {
                  setShow(!show)
                  setPaymentMethodModel(!paymentMethodModel)
               };
            };

            if (dataObj) {
               if (coupon.coupon !== '') dataObj.appliedCoupon = coupon.coupon;
               let response = await Axios.post("/api/influencer-videos/create-payment", dataObj).catch(e => console.error('Axios-Catch: ' + e.message));

               if (response && response.status === 200) {
                  if (response.data.data) {
                     setRedirectPage(`/content-creation/${response.data.data.OneoffUserSubscription._id.toString()}/thank-you`);
                     setPaid(true);
                     setShow(!show);
                     setReinit(reinit + 1);
                  } else {
                     setErrorMessage(response.data.message);
                  };
               } else {
                  setErrorMessage('Server Error!! Try again later.');
               };
            };
         } catch (error) {
            console.error('Error-Catch: ' + error.message);
         };
         dispatch({ type: 'loader_hide', response: true });
      };
   };

   const applyCoupon = async () => {
      try {
         let errorMsg = '';

         if (coupon.coupon != '') {
            dispatch({ type: 'loader_show', response: true });
            const response = await Axios.post('/api/apply-coupon', { coupon: coupon.coupon }).catch(e => console.error('Axios-Catch: ' + e.message));

            if (response && response.status === 200) {
               if (response.data.result) {
                  if (response.data.result.type == 'percentage') {
                     currentObject.finalPrice = parseFloat(parseFloat(currentObject.price) - parseFloat((currentObject.price * response.data.result.amount_or_percentage) / 100));
                  } else {
                     currentObject.finalPrice = parseFloat(parseFloat(currentObject.price) - parseFloat(response.data.result.amount_or_percentage));
                  };
                  coupon.validCoupon = true;
                  setCurrentObject({ ...currentObject });
               } else {
                  errorMsg = response.data.message || 'Invalid coupon';
                  setCouponModel(!couponModel);
               };
            } else {
               errorMsg = 'Invalid coupon';
               setCouponModel(!couponModel);
            };
         };

         setCoupon({
            ...coupon,
            errorMsg,
         });
      } catch (error) {
         console.error('Error-Catch: ' + error.message);
      };
      dispatch({ type: 'loader_hide', response: true });
   };

   const getUserDetail = async () => {
      try {
         dispatch({ type: 'loader_show', response: true });
         const response = await Axios.get('/api/user?expiry=true').catch(e => console.error('Axios-Catch: ' + e.message));
         if (response && response.status === 200) {
            setUser(response.data.data);
            setUserFetch(false);
         };
      } catch (error) {
         console.error('Error-Catch: ' + error.message);
      };
      dispatch({ type: 'loader_hide', response: true });
   };

   return (
      <>
         {isUserLoggedIn == false && <Header />}
         {influencer && <>
            <section id="home" className="hero-area hero-inner-area influencers-details-banner">
               <div className="container">
                  <div className="row align-items-center">
                     <div className="col-lg-12 col-md-12 col-12">
                        <div className="hero-content">
                           <h1 className="mb-0">
                              {influencer.name}
                           </h1>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <section id="influencersdetails" className="pricing-table section pt-2">
               <div className="container">
                  <div className="row">
                     <div className="col-lg-3 col-md-6 col-12">
                        <img src={influencer.image} className="mb-2 user_img influencersuser-img" alt="" />
                        <p className="mb-0"><strong>{t('common.name')}: </strong>{influencer.name}</p>
                        {influencer.followers && <p className="mb-0"><strong>{t('common.followers')}: </strong>{influencer.followers}</p>}
                        {influencer.tiktok_url && <Link to={influencer.tiktok_url} target="_blank">
                           <strong>{t('common.tiktok_profile')}</strong>
                        </Link>}
                     </div>
                     <div className="col-lg-9 col-md-6 col-12">
                        <div className='row'>
                           {influencer.price_list.map((v, i) => (
                              <div className="col-lg-6 col-xs-6 col-12">
                                 <div className="single-table">
                                    <div className="table-head">
                                       <div className="text-primary mb-2"><strong>Plan {i + 1}</strong></div>
                                       <p className="mb-2">{v.description}</p>
                                       <p className="mb-2"><strong>${v.price.toFixed(2)}</strong></p>
                                       <div className="button mt-3">
                                          <Button variant="primary" size="sm" className='py-2 px-3' onClick={() => { resetStates(); setCurrentObject({ ...influencer, description: v.description, dbPlanId: v._id, price: v.price, finalPrice: v.price }); setShow(!show) }}>
                                             {t('pricing.pay_now')}
                                          </Button>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           ))}
                        </div>
                     </div>
                  </div>
               </div>
            </section>
         </>}

         {isUserLoggedIn == false && <section className="section call-action">
            <div className="container">
               <div className="row">
                  <div className="col-lg-12 col-md-12 col-12">
                     <div className="cta-content">
                        <h2>{t('pricing.focus_on_tiktok_analytics')}</h2>
                        <div className="button">
                           <a href="/pre-register" className="btn">{t('pricing.start_free_trial')}</a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>}

         {isUserLoggedIn == false && <Footer />}
         <Modal show={show} onHide={() => setShow(!show)} backdrop="static" keyboard={false}>
            <Modal.Header>
               <Modal.Title>{t('pricing.complete_purches_message')}</Modal.Title>
               <Button variant="danger" className="close" size="sm" onClick={() => setShow(!show)}>
                  X
               </Button>
            </Modal.Header>
            <Modal.Body className="py-2">
               <p className="mb-0 text-center">{t('pricing.desc_1')}</p>
               <p className="mb-2 text-center"><strong>{t('pricing.order_summary')}</strong></p>
               <table className="table w-100 border">
                  <tr>
                     <td className="py-2">{t('pricing.keyword_launch')}</td>
                     <td className="py-1 text-right">
                        {currentObject
                           ?
                           coupon.coupon !== '' && coupon.validCoupon === true
                              ?
                              <>{`$${currentObject.finalPrice.toFixed(2)}`} <span className='line-through'>{`$${currentObject.price.toFixed(2)}`}</span></>
                              :
                              `$${currentObject.price.toFixed(2)}`
                           :
                           '$--'
                        }
                     </td>
                  </tr>
                  <tr>
                     <td className="py-2 text-primary">{t('pricing.total')} :</td>
                     <td className="py-2 text-primary text-right">{currentObject ? `$${(currentObject.finalPrice * qty).toFixed(2)}` : '$--'}</td>
                  </tr>
               </table>
               <Button onClick={() => setOpen(true)} aria-controls="example-collapse-text" aria-expanded={open} size='xs' variant="link" className="p-0">
                  {t('pricing.promo_message')}
               </Button>
               <Collapse in={open}>
                  <div id="example-collapse-text">
                     <InputGroup className="mb-3">
                        <Form.Control
                           placeholder={t('pricing.apply_promo')}
                           aria-describedby="basic-addon2"
                           className="form-control-sm"
                           type="text"
                           value={coupon.coupon}
                           onChange={(e) => { setCoupon({ ...coupon, coupon: (e.target.value).trim().toUpperCase(), validCoupon: false }); setCurrentObject({ ...currentObject, finalPrice: currentObject.price }) }}
                        />
                        <Button variant="primary" size="xs" id="button-addon2" onClick={() => applyCoupon()}>{t('pricing.apply')}</Button>
                     </InputGroup>
                  </div>
               </Collapse>
               <div className="d-flex mt-3 mb-3">
                  <Form.Control
                     type="text"
                     className="w-75 form-control-sm"
                     value={t('pricing.desc_3')}
                     disabled
                  />
                  <Form.Select className="w-25 form-control-sm" defaultValue={qty} onChange={(e) => setQty(e.target.value)}>
                     {buyArray.map((v, i) => { return <option key={i} value={v}>{v}</option> })}
                  </Form.Select>
               </div>
               {isUserLoggedIn === false && <div>
                  <div className="border p-3 mb-3">
                     <p className="lead mb-2">{t('pricing.personal_information')}</p>
                     <Form.Control
                        type="text"
                        className={`form-control form-control-sm ${nameMessage ? 'is-invalid' : ''} mb-2`}
                        placeholder={t('common.name')}
                        value={name}
                        onChange={(e) => { setName(e.target.value); setNameMessage(''); }}
                        onBlur={() => !name ? setNameMessage(t('common.name_message')) : ''}
                        required
                     />
                     {nameMessage && <div className="invalid-feedback">{nameMessage}</div>}
                     <Form.Control
                        type="email"
                        className={`form-control form-control-sm ${emailMessage ? 'is-invalid' : ''} mb-2`}
                        placeholder={t('registration.step_1.email_address')}
                        value={email}
                        onChange={(e) => { setEmail(e.target.value); setEmailMessage('') }}
                        onBlur={(e) => !email ? setEmailMessage(t('registration.step_1.long_text_2')) : checkemail(e.target.value)}
                        required
                     />
                     {emailMessage && <div className="invalid-feedback">{emailMessage}</div>}
                     <Form.Control
                        className={`form-control form-control-sm ${phoneMessage ? 'is-invalid' : ''} mb-2`}
                        placeholder={t('registration.step_3.phone_number')}
                        value={phone}
                        type="number"
                        onChange={(e) => { setPhone(e.target.value); setPhoneMessage('') }}
                        onBlur={(e) => !phone ? setPhoneMessage(t('registration.step_3.long_text_10')) : checkPhoneNumber(e.target.value)}
                        required
                     />
                     {phoneMessage && <div className="invalid-feedback">{phoneMessage}</div>}
                     <Form.Control onClick={() => setAddressMessage('')}
                        ref={addressRef}
                        type="text"
                        className={`form-control form-control-sm ${addressMessage ? 'is-invalid' : ''}`}
                        placeholder="Full Address"
                        onChange={() => setAddressMessage('')}
                        onBlur={() => !address ? setAddressMessage(t('user_profile.address_is_required')) : ''}
                        onSelect={() => { onSelectAddress() }}
                        required
                     />
                     {addressMessage && <div className="invalid-feedback">{addressMessage}</div>}
                  </div>
                  <div className="border p-3 mb-3" onClick={() => setStripeMessage('')}>
                     <p className="lead mb-2">{t('pricing.credit_card_detail')}</p>
                     <Elements stripe={stripePromise}>
                        <StripeCard ref={stripeRef} />
                     </Elements>
                     {stripeMessage && <div className="text-danger">{stripeMessage}</div>}
                     {errorMessage && <div className="text-danger">{errorMessage}</div>}
                  </div>
               </div>}
               {isUserLoggedIn === true && user !== null && <div>
                  <div className="border p-3 mb-3">
                     <h4>{t('user_profile.payment_method')}</h4>
                     <div className="d-flex mt-3 mb-3">
                        <input type="text" className="w-75 form-control form-control-sm" disabled
                           value={`**** **** **** ${user.pm_last_four !== null ? user.pm_last_four : '****'}`}
                        />
                        <input type="text" className="w-25 form-control form-control-sm" value={user.pm_type} disabled />
                     </div>
                     <div className="d-flex">
                        <input type="text" className="w-50 form-control form-control-sm" value={`****`} disabled />
                        <input type="text" className="w-50 form-control form-control-sm" value={`****`} disabled />
                     </div>
                  </div>
                  <div className="d-flex my-3">
                     <div className="w-50"></div>
                     <Link className="w-50" to="/profile?tab=billing" target="_blank"><small><strong>{t('pricing.change_payment_method')}</strong></small></Link>
                  </div>
               </div>}
               <p className="text-center"><Button variant="primary" size="sm" onClick={() => { setStripeMessage(''); setErrorMessage(''); payNow(); }}>Pay {currentObject ? `$${(currentObject.finalPrice * qty).toFixed(2)}` : '$--'}</Button></p>
               <p className="text-center">{t('pricing.desc_4')}</p>
            </Modal.Body>
         </Modal>

         <Modal show={couponModel} onHide={() => setCouponModel(!couponModel)} backdrop="static" keyboard={false} centered>
            <Modal.Body className="text-center">
               <span className="bolt-icon d-inline-block mb-4">
                  <i className="fa fa-times" aria-hidden="true"></i>
               </span>
               <h3 className="mb-3">{t('registration.modal.long_text_1')}</h3>
               <Button variant="primary" size="sm" className="mb-3" onClick={() => setCouponModel(!couponModel)}>
                  {t('registration.modal.long_text_2')}
               </Button>
               <p>
                  {t('registration.modal.long_text_3')}
               </p>
            </Modal.Body>
         </Modal>

         <Modal show={paymentMethodModel} onHide={() => setPaymentMethodModel(!paymentMethodModel)} backdrop="static" keyboard={false} centered>
            <Modal.Body>
               <div className="text-center">
                  <i className="fa fa-times-circle display-1 text-danger" aria-hidden="true"></i>
                  <h3 className="mt-4">{t('pricing.desc_5')}</h3>
                  <p>{t('pricing.desc_6')} <Link to="/profile?tab=billing" target="_blank"><strong>{t('pricing.click_hear')}</strong></Link></p>
                  <Button variant="primary" size="sm" onClick={() => setPaymentMethodModel(!paymentMethodModel)}>
                     {t('pricing.final_message')}
                  </Button>
               </div>
            </Modal.Body>
         </Modal>
      </>
   );
};

export default InfluencersDetails;