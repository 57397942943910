import { React, useState, useContext } from 'react';
import { Button, Form, Card, OverlayTrigger, Popover, Image } from 'react-bootstrap';
import Axios from '../../plugins/axios';
import { Link, useLocation } from "react-router-dom";
import { Context } from '../../../MasterLayout';
import { DateFormat } from '../CommonComponents/FormatNumber'
import Conversion from '../CommonComponents/Conversion'
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from "react-toastify";

const ProductVideoSalesEstimate = () => {
   const { t } = useTranslation();
   const { dispatch } = useContext(Context);
   const [videoId, setVideoId] = useState('');
   const [details, setDetails] = useState({});

   const handleSearch = async () => {
      try {
         let id = videoId;
         let isValid = isValidUrl(videoId);
         if (isValid == true) {
            let url = new URL(videoId).pathname;
            if (url.split("/")[3]) {
                id = url.split("/")[3];
            } else {
               return toast.error(t('common.invalid_url'), {
                  position: "top-center",
                  autoClose: 3000,
               });
            }
         }
         if (id != '') {
            const response = await Axios.post('/api/video-detail', { video_id: id })
            dispatch({ type: 'subscription_expired', response: response.data.statusCode });
            if (response && response.status === 200 && response.data.data) {
                setDetails(response?.data?.data);
            } else {
               setDetails({});
               return toast.error(t('common.no_data_found'), {
                  position: "top-center",
                  autoClose: 3000,
               });
            }
         }
     } catch (error) {
         console.error(error);
     }
      //const response = await Axios.post("/api/coupon", data);

   };

   const isValidUrl = (string) => {
     try {
       new URL(string);
       return true;
     } catch (err) {
       return false;
     }
   }

   const type = (e) => {
     const arrayOfStrings = e?.split(/,\s*/);
     const name = arrayOfStrings?.map(cat => {
         const matchedCategory = global.config?.chineseCategories?.find(chCat => chCat.namecn === cat || chCat.name === cat);
         return matchedCategory?.name;
     })
     return name?.join(',')
   }

   return (
      <div className="custom-tab-1">
            <ToastContainer />
            <Form>
               <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Card>
                        <Card.Body>
                            <Form.Label><strong>{t('common.product_video_id')}</strong></Form.Label>
                            <div className='d-flex align-items-center'>
                                <Form.Control size='sm' className='w-50' value={videoId} onChange={(e) => setVideoId((e.target.value).trim())} type="text" placeholder={t('common.product_video_id')} />
                                <Button onClick={handleSearch} className='ms-2' size='xs'>{t('common.search')}</Button>
                            </div>
                        </Card.Body>
                    </Card>
               </Form.Group>
            </Form>
            { details.id &&
            <Card>
              <Card.Body>
                  <div className='row'>
                      <div className='col-lg-1 text-center'>
                          <div className="prd-img mr-3">
                              <a href={`https://www.tiktok.com/@${details.author_unique_id}/video/${details.id}`} target='_blank' rel="noreferrer">
                                  <img alt="" width={75} height={75} className="prod-thum-img" src={`https://t-img.picturehaven.net/tikmeta/${details?.origin_cover_privatization}?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1`} />
                              </a>
                          </div>
                      </div>
                      <div className='col-lg-11'>
                          <div className='d-flex align-items-start justify-content-between'>
                              <div className='d-flex align-items-center'>
                                  <h4 className='mb-0 mr-5'>
                                      {details?.desc ? `${details?.desc.length > 40 ? details?.desc.substring(0, 42) + '...' : details?.desc}` : '--'}
                                  </h4>                             
                              </div>
                              <div className="text-right">
                                  <p className="mb-0"><small>{t('product_research.product_details.data_shown_below_are')}</small></p>
                                  <p className="mb-0"><small>{t('product_research.product_details.time_of_first_discovery')}:</small></p>
                              </div>
                          </div>
                          <div className='row'>
                              <div className='col-lg-4'>
                                  <div className='mb-2'>
                                      <p className='mb-0'><strong>{t('product_research.product_details.post_date')}:</strong>
                                          &nbsp;&nbsp;
                                          {details?.create_time ? <DateFormat timestamp={details?.create_time} /> : '--'}
                                          <span></span></p>
                                      <p className='mb-0'><strong>{t('product_research.product_details.video_duration')}:</strong>{details?.video_duration ? `${details?.video_duration} s` : '--'}<span></span></p>
                                  </div>
                                  <div className='d-flex align-items-center'>
                                      <OverlayTrigger
                                          key="right"
                                          trigger={["hover", "focus"]}
                                          placement="right"
                                          rootClose
                                          overlay={
                                              <Popover>
                                                  <Popover.Body>
                                                      {details.author_avatar_larger_privatization && <img src={"https://t-img.picturehaven.net/tikmeta/" + details.author_avatar_larger_privatization + "?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={400} className="prod-thum-img" />}
                                                  </Popover.Body>
                                              </Popover>
                                          }
                                      >
                                          <Button variant="link" className='p-0' size="xs">
                                              <Link to={'/influencers/details/' + details.author_id + "/breakout"} target="_blank">
                                                  {details.author_avatar_larger_privatization && <Image src={"https://t-img.picturehaven.net/tikmeta/" + details.author_avatar_larger_privatization + "?imageMogr2/auto-orient/thumbnail/100x/strip/format/WEBP/quality/75!/ignore-error/1"} alt="" width={60} className="prod-thum-img" roundedCircle />}
                                              </Link>
                                          </Button>
                                      </OverlayTrigger>
                                      <div className="prd-title ml-2">
                                          <Link to={'/influencers/details/' + details.author_id + "/breakout"} target="_blank">
                                              <p className="mb-0">{details?.author_nickname}</p>
                                          </Link>
                                      </div>

                                  </div>
                                  <div className='d-flex flex-wrap mb-3'>
                                      <p className='mb-0 mr-3'>
                                          {details?.author_unique_id ? `${details?.author_unique_id.length > 8 ? "@" + details?.author_unique_id.substring(0, 6) + '...' : "@" + details?.author_unique_id}` : '--'}
                                      </p>
                                      <p className='mb-0 mr-3'>{t('common.followers')}：{details?.follower_count ? <Conversion value={details?.follower_count} /> : '--'}</p>
                                      <p className='mb-0 mr-3'>{t('common.videos')}：{details?.video_count ? <Conversion value={details?.video_count} /> : '--'}</p>
                                      <p className='mb-0 mr-3'>{t('product_research.product_details.type')}：{details?.author_categories ? type(details?.author_categories) : '--'}</p>
                                  </div>
                              </div>

                              <div className='col-lg-8'>
                                  <div className='d-flex flex-wrap'>
                                      <div className='mr-5 mb-4'>
                                          <p className='mb-0'><strong>{t('common.revenue')}</strong></p>
                                          <h3 className='mb-0'>{details?.total_sales_amount_usd ? details?.total_sales_amount_usd : '--'}</h3>
                                          <p className='mb-0'> {details?.total_sales_amount ? `(${details?.total_sales_amount ? details?.total_sales_amount : '--'})` : '--'}</p>
                                          <p className='mb-0'>{t('product_research.product_details.than_yesterday')}</p>
                                          <p className='mb-0'><span className='text-success'>{details?.increase_total_sales_amount_usd ? `+${details?.increase_total_sales_amount_usd}` : '-'} </span>&nbsp;&nbsp;({details?.increase_total_sales_amount_str ? details?.increase_total_sales_amount_str : '--'})</p>
                                      </div>
                                      <div className='mr-5 mb-4'>
                                          <p className='mb-0'><strong>{t('product_research.product_details.arv')}</strong></p>
                                          <h3 className='mb-0'>{details?.avg_price_usd ? details?.avg_price_usd : '--'}</h3>
                                          <p className='mb-0'>{details?.avg_price ? `(${details?.avg_price})` : '--'}</p>
                                      </div>
                                      <div className='mr-5 mb-4'>
                                          <p className='mb-0'><strong>{t('product_research.product_details.sales_volume')}</strong></p>
                                          <h3 className='mb-0'>{details?.total_sales_volume ? details?.total_sales_volume : '--'}</h3>
                                          <p className='mb-0'>{t('product_research.product_details.than_yesterday')}</p>
                                          <p
                                              className={` ${details?.increase_total_sales_volume > 0 ? 'text-success mb-0' :
                                                  details?.increase_total_sales_volume < 0 ? 'text-danger mb-0' :
                                                      'text-muted mb-0'
                                                  }`}
                                          >
                                              {details?.increase_total_sales_volume > 0 && "+"}
                                              {details?.increase_total_sales_volume ? details?.increase_total_sales_volume : '--'}</p>
                                      </div>
                                      <div className='mr-5 mb-4'>
                                          <p className='mb-0'><strong>{t('common.views')}</strong></p>
                                          <h3 className='mb-0'>{details?.play_count ? <Conversion value={details?.play_count} /> : '--'} </h3>
                                          <p className='mb-0'>{t('product_research.product_details.than_yesterday')} -</p>
                                      </div>
                                      <div className='mr-5 mb-4'>
                                          <p className='mb-0'><strong>{t('product_research.product_details.conversion_rate')}</strong></p>
                                          <h3 className='mb-0'>{details?.conversion_rate ? `${(details?.conversion_rate * 100).toFixed(2)}%` : '0.00%'}</h3>
                                          <p className='mb-0'>{t('product_research.product_details.than_yesterday')}</p>
                                          <p className={` ${(details?.increase_conversion_rate * 100).toFixed(2) > 0 ? 'text-success mb-0' :
                                              (details?.increase_conversion_rate * 100).toFixed(2) < 0 ? 'text-danger mb-0' :
                                                  'text-muted mb-0'
                                              }`}
                                          >
                                              {(details?.increase_conversion_rate * 100).toFixed(2) > 0 && "+"}
                                              {details?.increase_conversion_rate ? `${(details?.increase_conversion_rate * 100).toFixed(2)}%` : '-'}</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </Card.Body>
          </Card>
             }
      </div>
   );
};

export default ProductVideoSalesEstimate;